<template lang="">
    <div class="card dveb-white-card tab-card">
          <b-tabs content-class="mt-3">
            <b-tab content-class="tab-content" title="İl" active><County/></b-tab>
            <b-tab title="İlçe"><District /></b-tab>
            <b-tab title="Mahalle / Köy"><Street /></b-tab>
          </b-tabs>
        </div>
</template>
<script>
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    components:{
        County : () => import("@/components/manage/location/list/County"),
        District : () => import("@/components/manage/location/list/District"),
        Street : () => import("@/components/manage/location/list/Street")


    },
    mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "İl - İlçe - Mahallesi Listesi", route: "/manage/location" },
    ]);
  },

}
</script>
<style lang="">
    
</style>